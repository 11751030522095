<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation step="0" :title="$t('L_FOLDER')" icon="folder"/>

    <div class="generalBorderlessBox">
        <!-- Large screen: filter -->
        <div v-if="total > 0 || isLoadProjs === true" class="row">

            <!-- Small: Add folder -->
            <div class="col-md-6 d-md-none">
                <div class="mb-3 float-end">
                    <div v-if="isSignOn === true" class="input-group">
                        <button type="button" class="btn btn-secondary" @click="$router.push({ path: '/uploadDoc' })">
                            <fa icon="folder-plus" class="me-2"/> Add Folder 
                        </button>
                        <button type="button" class="btn btn-secondary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
                            <span class="visually-hidden">Toggle Dropdown</span>
                        </button>
                        <ul class="dropdown-menu dropdown-menu-end">
                            <li><a class="dropdown-item" href="#"><fa icon="sitemap" class="me-2" />Change Request - AccMgr</a></li>
                            <li><a class="dropdown-item" href="#"><fa icon="sitemap" class="me-2" />Change Request - Developer</a></li>
                            <li><a class="dropdown-item" href="#"><fa icon="sitemap" class="me-2" />Deployment form</a></li>
                        </ul>
                    </div>
                    <span v-else @click="$router.push({ path: '/uploadDoc' })" class="text-primary isLink">
                        <fa icon="folder-plus" class="me-2"/>Add folder
                    </span>
                </div>
            </div>
            <!-- Small: Add folder end -->

            <div class="col-md-6 mb-2">
                <div class="input-group">
                    <input type="text" v-model="filterName" class="form-control" placeholder="Search" style="border-right: none;">
                    <span class="input-group-text" @click="showFilter = !showFilter" style="cursor: pointer">
                        <span v-if="totalFilter > 0" class="position-absolute top-0 translate-middle badge rounded-pill bg-danger" style="margin-left: 22px">{{ totalFilter }}</span>
                        <fa icon="sliders" />
                    </span>
                    <span v-if="totalFilter > 0" class="input-group-text" @click="resetSearch" style="cursor: pointer">
                        <fa icon="rotate-right" />
                    </span>
                    
                </div>
            </div>
            
            <!-- Small: Filter -->
            <div v-if="showFilter === true" class="col-12 mb-2 d-sm-none">
                <div class="mt-2">
                    <span class="filter-pill-border">
                        <span class="filter-item-pill" :class="filterStatus === 'WIP' ? 'active' : ''" @click="filterStatus = 'WIP'">WIP</span>
                        <div class="vr"></div>
                        <span class="filter-item-pill" :class="filterStatus === 'CIRCULATING' ? 'active' : ''" @click="filterStatus = 'CIRCULATING'">CIRCULATING</span>
                        <div class="vr"></div>
                        <span class="filter-item-pill" :class="filterStatus === 'SEALED' ? 'active' : ''" @click="filterStatus = 'SEALED'">SEALED</span>
                        <div class="vr"></div>
                        <span class="filter-item-pill" :class="filterStatus === 'DELETE' ? 'active' : ''" @click="filterStatus = 'DELETE'">DELETE</span>
                    </span>
                </div>

                <!-- <div class="mt-3 d-flex flex-wrap justify-content-between"> -->
                <div class="my-4">
                    <span class="filter-pill-border me-2">
                        <span class="filter-item-pill" :class="filterMode === 'SIMPLE' ? ' active' : ''" @click="filterMode = 'SIMPLE'; filterSignMode = ''">
                            <Popper class="popperDark" arrow hover content="SIMPLE">
                                <fa :icon="['far', 'circle']" size="lg" />
                            </Popper>
                        </span>
                        <div class="vr"></div>
                        <span class="filter-item-pill" :class="filterMode === 'KYC' ? ' active' : ''" @click="filterMode = 'KYC'">
                            <Popper class="popperDark" arrow hover content="KYC">
                                <fa icon="circle-half-stroke" size="lg"/>
                            </Popper>
                        </span>
                        <div class="vr"></div>
                        <span class="filter-item-pill" :class="filterMode === 'STRICT' ? ' active' : ''" @click="filterMode = 'STRICT'">
                            <Popper class="popperDark" arrow hover content="STRICT">
                                <fa icon="circle"  size="lg"/>
                            </Popper>
                        </span>
                    </span>

                    <span class="filter-pill-border">
                        <span class="filter-item-pill" :class="filterFav ? 'active' : 'disabled'" @click="filterFav = !filterFav">
                            <Popper class="popperDark" arrow hover content="Favorite">
                                <fa icon="star" />
                            </Popper>
                        </span>
                    </span>
                </div>

                <div class="mt-1 mb-2">
                    <span class="filter-pill-border">
                        <span class="filter-item-pill" :class="filterPrivacy === 'PUBLIC' ? 'active' : ''" @click="filterPrivacy = 'PUBLIC'">PUBLIC</span>
                        <div class="vr"></div>
                        <span class="filter-item-pill" :class="filterPrivacy === 'PRIVATE' ? 'active' : ''" @click="filterPrivacy = 'PRIVATE'">PRIVATE</span>
                        <div class="vr"></div>
                        <span class="filter-item-pill" :class="filterPrivacy === 'CONFIDENTIAL' ? 'active' : ''" @click="filterPrivacy = 'CONFIDENTIAL'">CONFIDENTIAL</span>
                    </span>
                </div>
                

            </div>
            <!-- Small: Filter end -->

            <!-- Large: Add folder -->
            <div class="col-md-6 d-none d-md-block">
                <div class="float-end">
                    <div v-if="isSignOn === true" class="input-group mb-3">
                        <button type="button" class="btn btn-secondary" @click="$router.push({ path: '/uploadDoc' })">
                            <fa icon="folder-plus" class="me-2"/> Add Folder 
                        </button>
                        <button type="button" class="btn btn-secondary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
                            <span class="visually-hidden">Toggle Dropdown</span>
                        </button>
                        <ul class="dropdown-menu dropdown-menu-end">
                            <li><a class="dropdown-item" href="#"><fa icon="sitemap" class="me-2" />Change Request - AccMgr</a></li>
                            <li><a class="dropdown-item" href="#"><fa icon="sitemap" class="me-2" />Change Request - Developer</a></li>
                            <li><a class="dropdown-item" href="#"><fa icon="sitemap" class="me-2" />Deployment form</a></li>
                        </ul>
                    </div>
                    <span v-else @click="$router.push({ path: '/uploadDoc' })" class="text-primary isLink">
                        <fa icon="folder-plus" class="me-2" style="margin-top: 12px" />Add folder
                    </span>
                </div>
            </div>
            <!-- Large: Add folder end -->

            <!-- Large: Filter -->
            <div v-if="showFilter === true" class="col-12 d-none d-sm-block">
                <div class="d-flex flex-wrap justify-content-between">
                    <span class="filter-pill-border">
                        <span class="filter-item-pill" :class="filterStatus === 'WIP' ? 'active' : ''" @click="filterStatus = 'WIP'">WIP</span>
                        <div class="vr"></div>
                        <span class="filter-item-pill" :class="filterStatus === 'CIRCULATING' ? 'active' : ''" @click="filterStatus = 'CIRCULATING'">CIRCULATING</span>
                        <div class="vr"></div>
                        <span class="filter-item-pill" :class="filterStatus === 'SEALED' ? 'active' : ''" @click="filterStatus = 'SEALED'">SEALED</span>
                        <div class="vr"></div>
                        <span class="filter-item-pill" :class="filterStatus === 'DELETE' ? 'active' : ''" @click="filterStatus = 'DELETE'">DELETE</span>
                    </span>

                    <span class="filter-pill-border">
                        <span class="filter-item-pill" :class="filterMode === 'SIMPLE' ? ' active' : ''" @click="filterMode = 'SIMPLE'; filterSignMode = ''">
                            <Popper class="popperDark" arrow hover content="SIMPLE">
                                <fa :icon="['far', 'circle']" size="lg" />
                            </Popper>
                        </span>
                        <div class="vr"></div>
                        <span class="filter-item-pill" :class="filterMode === 'KYC' ? ' active' : ''" @click="filterMode = 'KYC'">
                            <Popper class="popperDark" arrow hover content="KYC">
                                <fa icon="circle-half-stroke" size="lg"/>
                            </Popper>
                        </span>
                        <div class="vr"></div>
                        <span class="filter-item-pill" :class="filterMode === 'STRICT' ? ' active' : ''" @click="filterMode = 'STRICT'">
                            <Popper class="popperDark" arrow hover content="STRICT">
                                <fa icon="circle"  size="lg"/>
                            </Popper>
                        </span>
                    </span>

                    <span class="filter-pill-border">
                        <span class="filter-item-pill" :class="filterPrivacy === 'PUBLIC' ? 'active' : ''" @click="filterPrivacy = 'PUBLIC'">PUBLIC</span>
                        <div class="vr"></div>
                        <span class="filter-item-pill" :class="filterPrivacy === 'PRIVATE' ? 'active' : ''" @click="filterPrivacy = 'PRIVATE'">PRIVATE</span>
                        <div class="vr"></div>
                        <span class="filter-item-pill" :class="filterPrivacy === 'CONFIDENTIAL' ? 'active' : ''" @click="filterPrivacy = 'CONFIDENTIAL'">CONFIDENTIAL</span>
                    </span>

                    <span class="filter-pill-border">
                        <span class="filter-item-pill" :class="filterFav ? 'active' : 'disabled'" @click="filterFav = !filterFav">
                            <Popper class="popperDark" arrow hover content="Favorite">
                                <fa icon="star" />
                            </Popper>
                        </span>
                    </span>
                </div>
            </div>
            <!-- Large: Filter end -->

        </div>

        <!-- Folder list -->
        <div class="row mt-4">
            <div class="col-12">
                <div class="generalContentBox border shadow-sm">
                    <div v-if="isLoadProjs === false" class="row">
                        <div class="col-12">
                            <fa icon="spinner" size="lg" class="me-2" />Loading...
                        </div>
                    </div>

                    <div v-else class="row">
                        <div class="col-12">
                            <div v-if="total === 0 && isLoadProjs === false" class="col-12">
                                <div class="row">
                                    <div class="col-12 col-md-8 col-lg-10">
                                        <div>
                                            Good 
                                            <span v-if="hour >= 5 && hour < 12">morning</span>
                                            <span v-if="hour >= 12 && hour <= 18">afternoon</span>
                                            <span v-if="(hour >= 18 && hour <= 23) || (hour >= 0 && hour < 5)">evening</span>
                                            {{ getDisplayName }}, you have an empty rack.
                                        </div>
                                        <div class="mt-3">
                                            You may create your first folder now. A folder can hold a single or multiple documents, for signing and for reference purposes.
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-2 text-end">
                                        <span @click="$router.push({ path: '/uploadDoc' })" class="text-primary isLink">
                                            <fa icon="folder-plus" class="me-2"/>Add folder
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="col-12">

                                <div v-if="isLoading" class="mb-3">
                                    <fa icon="spinner" size="lg" class="me-2" />Loading...
                                </div>  

                                <div v-if="!isLoading" class="mb-3 text-end small fst-italic">{{totalShown}} of {{total}}</div>
                                <div ref="scrollComponent">
                                    <table class="table table-hover">
                                        <tbody>
                                            <tr v-for="proj in arrDgProj" :key="proj.folderId">
                                                <td> 
                                                    <router-link :to="proj.status === 'WIP' ? '/uploadDoc/' + proj.folderId : '/p/' + proj.folderId">
                                                        <div class="row" >
                                                            <div class="col-lg-9">
                                                                <table height="35px" border="0">
                                                                    <tr>
                                                                        <td class="align-middle">

                                                                            <!-- tmp hide set favorite <span class="isLink" style="width: 120px;">
                                                                                <span v-if="proj.isFavourite === true" class="star">
                                                                                    <Popper class="popperDark" arrow hover content="Click to remove from favorite">
                                                                                        <fa icon="star" size="lg" color="orange" @click.prevent="updFavorite(proj.folderId, pIdx, false)"/>
                                                                                    </Popper>
                                                                                </span>
                                                                                <span v-if="proj.isFavourite === false || proj.isFavourite === undefined" class="star">
                                                                                    <Popper class="popperDark" arrow hover content="Click to add to favorite">
                                                                                        <fa icon="star"  size="lg" @click.prevent="updFavorite(proj.folderId, pIdx, true)" class="text-grey" />
                                                                                    </Popper>
                                                                                </span>
                                                                            </span> -->

                                                                            <span style="width: 120px;">
                                                                                <span v-if="proj.isFavourite === true" class="isLabel me-1">
                                                                                    <fa icon="star" size="lg" color="orange" />
                                                                                </span>
                                                                                <span v-if="proj.isFavourite === false || proj.isFavourite === undefined" class="isLabel me-1">
                                                                                    <fa icon="star"  size="lg" class="text-grey" />
                                                                                </span>
                                                                            </span>

                                                                            <ProjStatus :status="proj.status" class="me-1" />

                                                                            <span v-if="proj.folderName" class="fw-bold me-1 greyLink">{{ proj.folderName }}</span>
                                                                            <span v-else class="fw-bold pe-1 greyLink">- </span>
                                                                            <Popper class="popperDark" arrow hover content="Document">
                                                                                <span class="badge bg-grey isTooltipsLabel me-1"><fa icon="file" /> {{ proj.totalDocument }}</span>
                                                                            </Popper>
                                                                            <Popper class="popperDark" arrow hover content="Signee">
                                                                                <span class="badge bg-grey isTooltipsLabel me-1"><fa icon="user" /> {{ proj.totalSignee }}</span>
                                                                            </Popper>

                                                                            <span class="badge tag isLabel" v-for="t in proj.jTag" :key="t">
                                                                                <fa icon="hashtag" class="pe-1"/>{{ t }}
                                                                                <span class="d-lg-none">
                                                                                    <a href="#" class="ms-1" @click.prevent="delTag(proj.folderId, proj.jTag, t)"><fa icon="xmark" /></a>
                                                                                </span>
                                                                                <span class="tagClose" @click.prevent="delTag(proj.folderId, proj.jTag, t)">
                                                                                    <fa icon="xmark" />
                                                                                </span>
                                                                            </span>
                                                                            
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <table align="right" height="35px" border="0">
                                                                    <tr>
                                                                        <td class="align-middle small text-grey">
                                                                            <div v-if="proj.dateModify !== 0">{{func.convDateTimeFormat(proj.dateModify, 'text')}}</div>
                                                                            <div v-else-if="proj.dateCreate !== 0">{{func.convDateTimeFormat(proj.dateCreate, 'text')}}</div>
                                                                            <div>
                                                                                <span v-if="proj.dateModify !== 0" class="isTooltipsLabel"><i>{{ func.getDateTimeDiff(func.convDateTimeFormat(proj.dateModify), new Date()) }}</i></span>
                                                                                <span v-else-if="proj.dateCreate !== 0" class="isTooltipsLabel"><i>{{ func.getDateTimeDiff(func.convDateTimeFormat(proj.dateCreate), new Date()) }}</i></span>
                                                                            </div>
                                                                        </td>
                                                                        <td width="50px" class="align-middle">
                                                                            <ImgAvatar :isUser="true" :id="proj.jCreator.jAvatar.userId" displayName="" :isVerified="proj.jCreator.isVerified" :isActive="proj.jCreator.status" privilege="OWNER" align="right" width="30" height="30" />
                                                                            <!-- <Avatar :isUser="true" :avatar="proj.jCreator.jAvatar.imgBase64" displayName="" privilege="OWNER" :isVerified="proj.jCreator.isVerified" :isActive="proj.jCreator.status" align="right" width="30" height="30" /> -->
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </router-link>

                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div v-if="isLoadingMore" class="mt-3">
                                    <fa icon="spinner" size="lg" class="me-2" />Loading...
                                </div>
                            </div>
                        </div>

                        <div v-if="total > 0" class="col-12">
                            <Legend folderStatus="true" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<!-- Pending for backend merge name & signee into a main search (i.e. current use filterName) -->

<script>
import { ref, inject, watch, onMounted, onUnmounted } from 'vue'
import TopNavigation from '@/components/TopNavigation.vue'
import Alert from '@/components/Alert.vue'
import Popper from 'vue3-popper'
// import { Tooltip } from 'bootstrap/dist/js/bootstrap.js'
import { useStore } from '@/stores/store'
import funcs from '@/functions/function'
import { useRouter, useRoute } from 'vue-router'
import ProjStatus from '@/components/ProjStatus.vue'
import Legend from '@/components/Legend.vue'
// import Avatar from '@/components/Avatar.vue'
import ImgAvatar from '@/components/ImgAvatar.vue'

// dgMode=part only
export default {
    name: 'ProjectList',
    components: {
        Alert, TopNavigation, Popper, /* Tooltip, */ ProjStatus, Legend, /* Avatar, */ ImgAvatar
    },
    setup() {
        const axios = inject('axios')
        const alert = ref([])
        const route = useRoute()
        const router = useRouter()
        const func = funcs

        const isSignOn = ref(false) // detect if from signon Entity
        const isLoadProjs = ref(false)   // to indicate has load the arrDgProj, arrDgProj cannot set to null by default
        const totalFilter = ref(0)
        const filterName = ref('')
        const filterSignee = ref('')
        const filterStatus = ref('') // /WIP/DONE/CANCELED
        const filterFav = ref(false)
        const filterMode = ref('') // SIMPLE/KYC/STRICT
        const filterSignMode = ref('') // PROJECT/DOCUMENT (Full Access/Restrict) - (KYC/STRICT Only) - changed to permission
        const filterPrivacy = ref('') // PUBLIC/PRIVATE/CONFIDENTIAL

        const scrollComponent = ref(null)
        const total = ref(0)        // total folder user can see w/o filter
        const totalShown = ref(0) // total records has been displayed
        const rcdFr = ref(0) // record index from 
        const rcdDisplay = ref(15) // total record to display, ORIG: 15
        const isLoading = ref(true)
        const isLoadingMore = ref(false)

        const arrProj = ref([])       // for dgMode=all, different fr arrDgProj
        const arrFilterProj = ref([]) // for dgMode=all only - handle filter and remain orig data in arrProj
        const arrDgProj = ref([])     // record shown in dg, total length shown as totalShown

        const hour = ref(null)
        const isStar = ref(false)
        const isAll = ref(true)
        const showFilter = ref(false) // toggle filter for large screen
        const showMobileFilter = ref(false) //toggle for small screen only

        const { getDisplayName, getUserId } = useStore()

        const d = new Date()
        hour.value = d.getHours()


        watch([filterName, filterSignee, filterStatus, filterFav, filterMode, filterSignMode, filterPrivacy], () => {
            if (filterName.value.length === 0 && filterSignee.value.length === 0 && filterStatus.value === '' && filterFav.value === false && filterMode.value === '' && filterSignMode.value === '' && filterPrivacy.value === '') {
                // console.info('- - WATCH - I')
                rcdFr.value = 0
                totalFilter.value = 0
                totalShown.value = 0
                arrProj.value = []
                arrDgProj.value = []
                iniDg() // reset filter

            } else if (filterName.value.length >= 3 || filterSignee.value.length >= 3 || filterStatus.value !== '' || filterFav.value !== false || filterMode.value !== '' || filterSignMode.value !== '' || filterPrivacy.value !== '') {
                // console.info('- - WATCH - II')
                totalFilter.value = 0
                if (filterName.value.length >= 3) totalFilter.value++
                if (filterSignee.value.length >= 3) totalFilter.value++
                if (filterStatus.value !== '') totalFilter.value++
                if (filterFav.value !== false)  totalFilter.value++
                if (filterMode.value !== '')  totalFilter.value++
                if (filterSignMode.value !== '')  totalFilter.value++
                if (filterPrivacy.value !== '')  totalFilter.value++

                if (totalFilter.value > 0) {
                    rcdFr.value = 0
                    totalShown.value = 0
                    arrDgProj.value = []
                    getProjList()
                }
            }
        })

        const iniDg = async () => {
            total.value = 0
            totalShown.value = 0
            rcdFr.value = 0

            arrProj.value = []
            arrDgProj.value = []

            getProjListIni()
        }

        // Get initial proj list, without search criteria & rcd limit to decide dgMode
        const getProjListIni = async () => {
            isLoading.value = true

            const p = {
                orderBy: [
                    {
                        field: 'name',
                        order: 'asc'
                    }
                ] 
            }
            await axios.post('/signon/list', p)
                .then((res) => {

                    if (res.data.status === 1001) {
                        router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        isLoadProjs.value = true
                        total.value = res.data.description.rowCount

                        // Query db partially based on scroll
                        arrProj.value = res.data.data
                        proDgAllData() // set arrDgProj to get partial data fr arrProj(full, no search criteria & rcd limit)
                        arrProj.value = arrDgProj.value  // Set back arrProj same as arrDgProj for 
        
                    } else {
                        func.addLog('folder', 'getProjListIni', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })
                        }

                    }
                    isLoading.value = false
                })
                .catch((error) => {
                    isLoading.value = false
                    isLoadProjs.value = true
                    func.addLog('folder', 'getProjListIni - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })
        }

        // For dgMode = part - to get more proj list
        const getProjList = async () => {

            isLoading.value = true

            let p = { 
                limit: rcdFr.value + ',' + rcdDisplay.value,
                orderBy: [
                    {
                        field: 'name',
                        order: 'asc'
                    }
                ] 
            }

            if (filterName.value !== '' && filterName.value.length >= 3) { 
                p.name = filterName.value
            }

            if (filterSignee.value !== '' && filterSignee.value.length >= 3) { 
                p.signee = filterSignee.value
            }

            if (filterStatus.value !== '') {
                p.status = filterStatus.value
            }

            if (filterFav.value === true) {
                p.isFavourite = filterFav.value
            }

            if (filterMode.value !== '') {
                p.mode = filterMode.value
            }

            if (filterSignMode.value !== '') {
                p.signMode = filterSignMode.value
            }

            if (filterPrivacy.value !== '') {
                p.privacy = filterPrivacy.value
            }

            // console.info('filter', JSON.stringify(p))

            axios.post('/signon/list', p)
                .then((res) => {

                    if (res.data.status === 1001) {
                        router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        total.value = res.data.description.rowCount

                        if (rcdFr.value === 0) {
                            totalShown.value = 0
                            arrDgProj.value = []
                        }
    
                        if (res.data.data.length > 0) {
                            let tmp = []
                            tmp = res.data.data

                            for (var i = 0; i < tmp.length; i++) {
                                arrDgProj.value.push(tmp[i])
                            }
                            totalShown.value = arrDgProj.value.length
                        }
                    } else {
                        func.addLog('folder', 'getProjList', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }
                    }
                    isLoading.value = false    
                })
                .catch((error) => {
                    isLoading.value = false
                    func.addLog('folder', 'getProjList - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })
        }

        const proDgAllData = async () => {

            let len = rcdFr.value + rcdDisplay.value

            if (filterName.value.length >= 3 || filterSignee.value.length >= 3 || filterStatus.value !== '' || filterFav.value !== false || filterMode.value !== '' || filterSignMode.value !== '' || filterPrivacy.value !== '') {
                // console.info('- - - proDgAllData - filter', rcdFr.value, rcdDisplay.value, 'arrFilterTeam', arrFilterTeam.value.length, JSON.stringify(arrFilterTeam.value))
                for (var i = rcdFr.value; i < len; i++) {
                    if (arrFilterProj.value[i] !== undefined) {
                        arrDgProj.value.push(arrFilterProj.value[i])
                    }
                }
                totalShown.value = arrDgProj.value.length
            }
            else
            {
                // console.info('- - - proDgAllData', rcdFr.value, rcdDisplay.value, 'len', len)
                for (var i = rcdFr.value; i < len; i++) {
                    if (arrProj.value[i] !== undefined) {
                        arrDgProj.value.push(arrProj.value[i])
                    }
                }
                totalShown.value = arrDgProj.value.length
            }

        }

        const resetSearch = async () => {
            filterStatus.value = ''
            filterFav.value = false
            filterMode.value = ''
            filterSignMode.value = ''
            filterPrivacy.value = ''
            filterName.value = ''
            filterSignee.value = ''

            rcdFr.value = 0
            totalFilter.value = 0
            totalShown.value = 0
            arrDgProj.value = []
            showFilter.value = false

        }

        const handleScroll = (e) => {
            let element = scrollComponent.value
            if (element !== null) {
                if (element.getBoundingClientRect().bottom.toFixed(0)*1 < window.innerHeight) {
                    let tmp = rcdFr.value + rcdDisplay.value

                    if (tmp < total.value) {
                        isLoadingMore.value = true
                        rcdFr.value = rcdFr.value + rcdDisplay.value
                        
                        getProjList()
                        isLoadingMore.value = false
                            
                    }
                }
            }
        }

        const closeAlert = (index) => {
            alert.value.splice(index, 1)

        }

        onMounted(() => {
            // initial tooltip
            /* Array.from(document.querySelectorAll('span[data-bs-toggle="tooltip"]'))
            .forEach(tooltipNode => new Tooltip(tooltipNode))

            Array.from(document.querySelectorAll('button[data-bs-toggle="tooltip"]'))
            .forEach(tooltipNode => new Tooltip(tooltipNode)) */

            if (func.isEmptyObject(localStorage.getItem('entity')) === false) {
                let objLSEntity = func.decodeStrToJsonSimple(localStorage.getItem('entity'))
                if ( objLSEntity.entityId === '1') {
                    isSignOn.value = true
                }
            }

            window.addEventListener("scroll", handleScroll)
            func.clearPrevLocalStorage()
            localStorage.removeItem('project')

            // First time login
            if (route.query.fl === true || route.query.fl === 'true') {
                alert.value.push({
                    class: "success",
                    title: "SUCCESS",
                    message: "Welcome to SignOn."
                })
            }

            // Not first time login
            if (route.query.fl === false || route.query.fl === 'false') {
                if (route.query.l) { 
                    alert.value.push({
                        class: "success",
                        title: "SUCCESS",
                        message: "Welcome back. Last login " + func.getDateTimeDiff(func.convDateTimeFormat(route.query.l), new Date()) + ", last active " + func.getDateTimeDiff(func.convDateTimeFormat(route.query.a), new Date()) + "."
                    })
                }
            }
            iniDg()

        })

        onUnmounted(() => {
            window.removeEventListener("scroll", handleScroll)
        })
        

        const updFavorite = (id, idx, f) => {
            const p = { isFavourite: f }

            axios.put( '/signon/' + id, p)
            .then((res) => {
                if (res.data.status === 1001) {
                    router.push({ path: '/auth/login', query: {e: 'ns'} })

                } else if (res.data.status === 1) {
                    arrDgProj.value[idx].isFavourite = f

                } else {
                    func.addLog('folder', 'getProjListIni', res)

                    if (res && res.data !== null && res.data !== undefined) {
                        alert.value.push({
                            class: "danger",
                            title: "ERROR",
                            message: func.getError(res.data)
                        })

                    }

                }
            })
            .catch((error) => {
                func.addLog('folder', 'updFavorite - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

            })
        }

        const delTag = async (id, ptags, val) => {
            let tmpTags = []
            ptags.forEach((item, idx) => {
                if (item !== val) {
                    tmpTags.push(item)
                }
            })

            const p = {
                jTag: tmpTags
            }

            axios.put( '/signon/' + id, p)
                .then((res) => {

                    if (res.data.status === 1001) {
                        router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        projects.value = []
                        getProjList('', '')

                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "Tag is added."
                        })

                    } else {
                        func.addLog('folder', 'delTag', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }
                    }
                })
                .catch((error) => {
                    func.addLog('folder', 'delTag - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })
        }

        return {
            func, alert, closeAlert, router, isLoadProjs, isLoading, isLoadingMore, getDisplayName, isSignOn, 
            scrollComponent, arrProj, arrFilterProj, arrDgProj, rcdFr, rcdDisplay, total, totalShown, 
            totalFilter, filterName, filterSignee, filterStatus, filterFav, filterMode, filterSignMode, filterPrivacy, resetSearch, 
            hour, updFavorite, delTag, isStar, isAll, showMobileFilter, showFilter, getUserId
            
        }

    } // setup
}
</script>

<style>
    .star {
        font-size: 14px;
        padding: 5px; 
    }

    .star:hover {
        background-color: #DADADB;
        padding: 5px;
        padding-bottom: 7px;
        padding-right: 5px;
        border-radius: 20px;
    }

    .docPill:hover {
        background-color: var(--doc-pill-hover);
    }

    .boxStatus, .boxStatusTop {
        border-radius: 10px;
        padding: 15px 25px;
        margin-left: 4px;
        margin-right: 4px;
    }

    @media (max-width: 575.98px) {
        .boxStatusTop {
            margin-bottom: 20px;
        }
    }

    .input-group-text {
        background-color: var(--bs-primary);
        color: var(--bgcolor-body-color);
        padding-top: 11px;
        padding-bottom: 11px;
        font-size: 14px;
    }

    .screenLarge {
        display: block;
    }

    .screenSmall {
        display: none;
    }

    @media (max-width: 575.98px) {
        .screenLarge {
            display: none;
        }

        .screenSmall {
            display: block;
        }
    }

</style>